import React, { useEffect } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import ReactGa from 'react-ga';
import { BallotBox } from '../../Components/BallotBox';


export default function Votation() {

  useEffect(() => {
		ReactGa.pageview('/votacao')
	}, [])

  return (
    <>
      <Header />
      <BallotBox />
      <Footer />
    </>
  )
}