import React, { useEffect, useState } from 'react'

import Manager from '../../../Components/Manager'

import { Box, Container, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core'
import { CustomButtom, IconCustom, TitleList } from '../../../Components/Customs'

import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { Link, PhotoCamera, ViewModule } from '@material-ui/icons';

import api from '../../../Services/api';
import { toast } from 'react-toastify';
import stylePagesManager from '../style';

DateTime.local().setLocale('pt')

const FormBanner = ({ match }) => {

	const style = stylePagesManager()

	const [positions, setPositions] = useState([])

	const [previewImg, setPreviewImg] = useState(false)

	const [data, setData] = useState({
		id: 0,
		title: '',
		date_input: DateTime.local(),
		date_output: DateTime.local(),
		order: '',
		position: '',
		file: [],
		link: '',
		status: '',
	})

	function handleValues(e) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	function handleFile(e) {
		setData({ ...data, file: e.target.files[0] })
		setPreviewImg(URL.createObjectURL(e.target.files[0]))
	}

	function handleDate(field, e) {
		setData({ ...data, [field]: DateTime.fromISO(e).toISO() })
	}

	async function getPositionsBanner() {
		const res = await api.get('banner_position/listAll')
		setPositions(res.data.rows)
	}

	async function getBanner() {
		if (match.params.id) {
			const res = await api.get(`banner/${match.params.id}`)
			setData({
				id: res.data.id,
				title: res.data.title,
				date_input: DateTime.fromSQL(res.data.date_input).toISO(),
				date_output: DateTime.fromSQL(res.data.date_output).toISO(),
				order: res.data.order,
				position: res.data.position,
				file: [],
				image_url: res.data.image_url,
				link: res.data.link,
				status: res.data.status,
			})
		}
	}

	async function envBanner(e) {
		e.preventDefault()

		const obj = new FormData()

		obj.append('title', data.title)
		obj.append('date_input', DateTime.fromISO(data.date_input).toFormat('yyyy-MM-dd'))
		obj.append('date_output', DateTime.fromISO(data.date_output).toFormat('yyyy-MM-dd'))
		obj.append('order', data.order)
		obj.append('position', data.position)
		obj.append('file', data.file)
		obj.append('link', data.link)
		obj.append('status', data.status)

		if (match.params.id) {
			try {
				await api.put(`banner/${match.params.id}`, obj)
				toast.success('Banner alterado com sucesso!')
			} catch (error) {
				toast.error('Falha ao atualizar banner!')
			}
		} else {
			try {
				await api.post(`banner`, obj)
				toast.success('Banner cadastrado com sucesso!')
			} catch (error) {
				toast.error('Falha ao cadastrar banner!')
			}
		}

	}

	useEffect(() => {
		getPositionsBanner()
		getBanner()
	}, [])

	return (
		<Manager>
			<Container maxWidth="md">
				<Box>
					<TitleList>Formulário Banner</TitleList>
				</Box>

				<form onSubmit={envBanner}>
					<Grid container spacing={2}>
						<Grid item xs={12}>

							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="" >Título do Banner</InputLabel>
								<OutlinedInput
									name="title"
									value={data.title}
									onChange={handleValues}
									label="Título do Banner"

								/>
							</FormControl>

						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
								<KeyboardDatePicker
									inputVariant="outlined"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									format="dd/MM/yyyy"
									margin="none"
									id="date_input"
									label="Data de entrada"
									name="date_input"
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									helperText=""
									value={data.date_input}
									onChange={e => handleDate('date_input', e)}
								/>
							</MuiPickersUtilsProvider>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
								<KeyboardDatePicker
									inputVariant="outlined"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									helperText=""
									format="dd/MM/yyyy"
									margin="none"
									id="date_output"
									label="Data de saída"
									name="date_output"
									fullWidth
									InputLabelProps={{
										shrink: true,

									}}
									value={data.date_output}
									onChange={e => handleDate('date_output', e)}
								/>
							</MuiPickersUtilsProvider>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="status-banner">Status</InputLabel>
								<Select
									labelId="status-banner"
									id="status-banner"
									name="status"
									value={data.status}
									onChange={handleValues}
									label="Status"
								>
									<MenuItem value={"blocked"}>Bloqueado</MenuItem>
									<MenuItem value={"actived"}>Ativo</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="position-banner">Posição do Banner</InputLabel>
								<Select
									labelId="position-banner"
									id="position-banner"
									name="position"
									value={data.position}
									onChange={handleValues}
									label="Posição do Banner"
								>
									{positions.map(position => (
										<MenuItem key={position.id} value={position.id}>{position.position_name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={6} md={4} >
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="order-banner">Ordem</InputLabel>
								<OutlinedInput
									name="order"
									id="order-banner"
									value={data.order}
									onChange={handleValues}
									label="ordem"
									type='number'
									startAdornment={
										<InputAdornment position="start">
											<ViewModule />
										</InputAdornment>
									}
								/>
								<FormHelperText></FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="link-banner">Link</InputLabel>
								<OutlinedInput
									name="link"
									id="link-banner"
									value={data.link}
									onChange={handleValues}
									label="Link"
									startAdornment={
										<InputAdornment position="start">
											<Link />
										</InputAdornment>
									}
								/>
								<FormHelperText></FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<Typography>Observações sobre imagens:</Typography>
							<ul>
								<li>Recomendado tamanho 686x540 para Banners Avisos</li>
								<li>Recomendado tamanho 1920x500 para Banners Destaques</li>
								<li>Recomendado tamanho 686x540 para Banners Destaques Mobiles</li>
							</ul>
						</Grid>

						<Grid item xs={12}>
							<Box display='flex'>
								<Box className={style.btnUploadForm} display='flex' justifyContent="center" alignItems="center">
									<input accept="image/*" id="file-banner" type="file" onChange={handleFile} />
									<label htmlFor="file-banner">
										<IconCustom coloricon="#fff" aria-label="imagem banner" component="span">
											<PhotoCamera />
										</IconCustom>
									</label>
								</Box>

								<Box className={style.boxPreviewImg}>
									{previewImg ? <img src={previewImg} alt="" /> : data.image_url && <img src={data.image_url} alt="" />}
								</Box>
							</Box>
						</Grid>

					</Grid>

					<Box display='flex' justifyContent='flex-end'>
						<CustomButtom bg="#f4393c" type="submit">
							{data.id ? 'Salvar' : 'Cadastrar'}
						</CustomButtom>
					</Box>
				</form>
			</Container>
		</Manager>
	)
}

export default FormBanner