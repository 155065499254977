import React from 'react';
import Manager from '../../../Components/Manager';
import { TitlePage } from '../../../Components/Customs'

function HomeManager() {
	return(
		<Manager>
			<TitlePage>Seja bem vindo(a) a área administrativa do intranet </TitlePage>
		</Manager>
	)
}

export default HomeManager;