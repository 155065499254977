import { Box, Container } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { CustomButtom, TitleList } from '../../../Components/Customs'
import Manager from '../../../Components/Manager'
import api from '../../../Services/api'
import stylePagesManager from '../style'

const TimeLine = ({ location }) => {

	const style = stylePagesManager()

	const [timeline, setTimeline ] = useState([])

	async function getTimeline(){
		const res = await api.get('employee_history/timeline')
		console.log(res)
		setTimeline(res.data)
	}

	useEffect( () => {
		getTimeline()
	}, [])

	return (
		<Manager>
			<Container maxWidth='xl'>
				<TitleList>Timeline da empresa</TitleList>
				<Box display='flex' justifyContent='center'>
					<table className={style.table} cellSpacing={0} cellPadding={0}>
						<thead>
							<tr>
								<th className={`title`}>Funcionário</th>
								<th className={`title`}>Tipo</th>
								<th className={`title`}>Observação</th>
								<th className={`title`}>Data</th>
								<th className={`title`}>Setor</th>
								<th className={`title`}>Departamento</th>
							</tr>
						</thead>

						<tbody>
							{timeline.map((row) => (
								<tr key={row.id}>
									<td>{row.name} {row.lastname}</td>
									<td>{row.type}</td>
									<td>{row.observation}</td>
									<td>{DateTime.fromISO(row.date_occurrence).toFormat('dd/MM/yyyy')}</td>
									<td>{row.name_sector}</td>
									<td>{row.name_departament}</td>
								</tr>
							))}
						</tbody>
					</table>
				</Box>
			</Container>
		</Manager>
	)
}

export default TimeLine