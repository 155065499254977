import React, { useRef, useState, useMemo } from 'react'
import JoditEditor from "jodit-react";
import { store } from '../../../store'

const Editor = ({ value, onChange, setNewContent }) => {

  const { token } = store.getState().auth

  const editor = useRef(null)
  const [content, setContent] = useState(value)
  const config = {
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: "https://santolabs.com.br/api/intranet/post/postImage",
      format: 'json',
      method: 'POST',
      headers: {
        "mimeType": "multipart/form-data",
        "contentType": false,
        "Authorization": `Bearer ${token}`
      },
      "insertImageAsBase64URI": true,
      "imagesExtensions": [
        "jpg",
        "png",
        "jpeg",
        "gif"
      ],
      pathVariableName: 'path',
      filesVariableName: 'files',

      prepareData: function (data) {
        return data;
      },
      isSuccess: function (resp) {
        console.log('respSucc: ', resp)
        return !resp.error;
      },
      process: function (resp) {
        console.log('process: ', resp)
        return {
          files: resp['files'] || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg
        };
      },

      defaultHandlerSuccess: function (data, resp) {
        var i, field = this.options.uploader.filesVariableName;

        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.selection.insertImage(data.baseurl + data[field][i]);
          }
        }
      },
    },
  }

  return useMemo(() => (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onChange={content => {
        setContent(content)
        setNewContent(content)
        onChange(content)
      }}
    />
  ), [])
}

export default Editor