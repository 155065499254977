const { makeStyles } = require("@material-ui/core");

const stylePageLogin = makeStyles( (theme) => ({
	root: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
	},

	boxLogin: {
		borderRadius: 10,
		boxShadow: '1px 1px 8px -5px #00000087',
		maxWidth: 350,
	},

	topMenu: {
		borderRadius: '10px 10px 0 0',
		background: '#f4393c',
		textAlign: "center",
		padding: 15,
	},

	formLogin: {
		boxSizing: 'border-box',
		background: '#fff',
		borderRadius: '0 0 10px 10px',
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
		paddingBottom: 30,
	},

	inputFormLogin: {
		paddingTop: 10,
		paddingBottom: 10
	},

	btnSignIn: {
		paddingTop: 10
	}

}))

export default stylePageLogin