import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import history from '../Services/history';
import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSagas';
import persistReducers from './persistReducers';

const sagaMiddleware = createSagaMiddleware(); //{ sagaMonitor }

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };