import React, { useEffect, useRef, useState, } from 'react'
import api from '../../../Services/api';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon'

import { Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Switch, Typography } from '@material-ui/core'
import { PhotoCamera, ViewModule, Link, Save } from '@material-ui/icons';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

import { CustomButtom, IconCustom, TitleList } from '../../../Components/Customs'
import Manager from '../../../Components/Manager'

import stylePagesManager from '../style';
import Editor from '../../../Components/Manager/Editor';
import { toast } from 'react-toastify';

function FormPost({ match, ...props }) {

	const url = window.location.href.split('/')[2]

	const style = stylePagesManager()

	const editor = useRef(null)
	const [modeEditor, setModeEditor] = useState('create')
	const [content, setContent] = useState('')
	const [newContent, setNewContent] = useState('')
	const [contentLoaded, setContentLoaded] = useState('')

	const [previewImg, setPreviewImg] = useState({
		file: false,
		file_mobile: false
	})

	const [addBanner, setAddBanner] = useState(false)
	const [previewImgCarousel, setPreviewImgCarousel] = useState([])

	const [positions, setPositions] = useState([])

	const [data, setData] = useState({
		id: 0,
		title: '',
		image: '',
		gallery: [],
		status: '',
		published_date: '',
		show_title: 0,
		file: [],
		file_mobile: [],
		files: []
	})

	const [banner, setBanner] = useState({
		date_input: DateTime.local().toISO(),
		date_output: DateTime.local().toISO(),
		order: '',
		position: '',
		file: [],
		link: '',
		status: 'blocked',
	})

	function handleValuesBanner(e) {
		const { name, value } = e.target
		setBanner({ ...banner, [name]: value })
	}

	function handleValues(e) {
		let { name, value } = e.target
		if (name === 'status') {
			const checked = e.target.checked
			if (!checked) value = 'unpublished'
		}

		if (name === 'show_title') {
			const checked = e.target.checked
			if (!checked) value = 0

			value = parseInt(value)
		}

		setData({ ...data, [name]: value })
	}

	function handleDate(field, e) {
		setBanner({ ...banner, [field]: e })
	}

	function handleFile(e) {
		const { name } = e.target
		setData({ ...data, [name]: e.target.files[0] })
		setPreviewImg({ ...previewImg, [name]: URL.createObjectURL(e.target.files[0]) })
	}

	function handleFiles(e) {
		const arr = []
		for (let i in e.target.files) {
			if (!e.target.files.hasOwnProperty(i)) continue;
			let obj = e.target.files[i];
			arr.push(URL.createObjectURL(obj))
		}
		setData({ ...data, files: Array.from(e.target.files) })
		setPreviewImgCarousel(arr)
	}

	function makeBanner(e) {
		const { checked } = e.target
		setAddBanner(checked)

		if (!checked) {
			setBanner({
				date_input: Date(),
				date_output: Date(),
				order: '',
				position: '',
				file: [],
				link: '',
				status: '',
			})
		}
	}

	async function getPositionsBanner() {
		const res = await api.get('banner_position/listAll')
		setPositions(res.data.rows)
	}

	async function getPost() {
		if (match.params.id) {
			const res = await api.get(`post/${match.params.id}`)
			setData({
				...data,
				id: res.data.id,
				title: res.data.title,
				image: res.data.image,
				image_mobile: res.data.image_mobile,
				gallery: (res.data.gallery ? res.data.gallery.split(',') : []),
				status: res.data.status,
				show_title: res.data.show_title,
				published_date: res.data.published_date
			})
			setContent(res.data.content)
			setModeEditor('update')
			setContentLoaded(true)
		}


	}

	async function envBanner(id) {
		const obj = new FormData()
		let file;

		if (data.file['name'] !== undefined) {
			file = data.file
		} else {
			file = data.image
		}

		const link = `http://${url}/noticia/${data.title.normalize("NFD").replace(/\s/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase()}-${id}`

		obj.append('title', data.title)
		obj.append('date_input', DateTime.fromISO(banner.date_input).toFormat('yyyy-MM-dd'))
		obj.append('date_output', DateTime.fromISO(banner.date_output).toFormat('yyyy-MM-dd'))
		obj.append('status', banner.status)
		obj.append('position', banner.position)
		obj.append('order', banner.order)
		obj.append('link', link)
		obj.append('file', file)

		try {
			const res = await api.post('banner', obj)
			console.log(res)
		} catch (error) {
			toast.error('Falha ao cadastrar banner!')
		}
	}

	async function envPost() {
		const obj = new FormData()
		const gallery = new FormData()

		obj.append('title', data.title)
		obj.append('file', data.file)
		obj.append('file_mobile', data.file_mobile)
		obj.append('content', newContent)
		obj.append('status', data.status)
		obj.append('show_title', parseInt(data.show_title))

		data.files.forEach((file, index) => {
			gallery.append('files', file)
		})
	
		if (match.params.id) {
			try {
				const res = await api.put(`post/${match.params.id}`, obj)

				await api.put(`post/gallery/${match.params.id}`, gallery)
				toast.success('Postagem atualizada com sucesso!')
				if (addBanner) envBanner(res.data.post.id)
			} catch (error) {
				toast.error('Falha ao atualizar postagem!')
			}
		} else {
			try {
				const res = await api.post(`post/`, obj)
				await api.put(`post/gallery/${match.params.id}`, gallery)
				toast.success('Postagem cadastrado com sucesso!')
				if (addBanner) envBanner(res.data.post.id)
			} catch (error) {
				toast.error('Falha ao cadastrar postagem!')
			}
		}
	}

	useEffect(() => {
		getPositionsBanner()
		if (match.params.id) {
			getPost()
		}
	}, [match.params.id])

	return (
		<Manager>
			<Container maxWidth="lg">
				<Box display='flex' justifyContent='space-between'>
					<TitleList>Postagem</TitleList>
					<CustomButtom
						onClick={envPost}
						endIcon={
							<Save />
						}
					>
						salvar
					</CustomButtom>
				</Box>

				<form action="">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel>Título da postagem</InputLabel>
								<OutlinedInput
									name='title'
									value={data.title}
									label="Título da postagem"
									onChange={handleValues}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FormGroup row>
								<FormControlLabel
									control={
										<Switch
											checked={data.show_title === 1 && true}
											value={1}
											name="show_title"
											onChange={handleValues}
										/>
									}
									label="Exibir apenas o conteudo na página desta noticia."
								/>
							</FormGroup>
						</Grid>

						<Grid item xs={12}>
							{modeEditor === 'create' && <Editor value={''} onChange={content => setContent({ content })} setNewContent={setNewContent} />}
							{modeEditor === 'update' && contentLoaded &&
								<Editor value={content} onChange={content => setContent({ content })} setNewContent={setNewContent} />
							}
						</Grid>

						<Grid item xs={12}>
							<Typography>Imagem Destaque Desktop</Typography>
							<ul>
								<li>Recomendado tamanho 1920x500 para Imagem</li>
							</ul>
						</Grid>

						<Grid item xs={12}>
							<Box display='flex'>
								<Box className={style.btnUploadForm} display='flex' justifyContent="center" alignItems="center">
									<input accept="image/*" id="file-destaque" name="file" type="file" onChange={handleFile} />
									<label htmlFor="file-destaque">
										<IconCustom coloricon="#fff" aria-label="imagem destaque desktop" component="span">
											<PhotoCamera />
										</IconCustom>
									</label>
								</Box>

								<Box className={`${style.boxPreviewImg} fixed`}>
									{previewImg.file ? <img src={previewImg.file} alt="" /> : data.image && <img src={data.image} alt="" />}
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Typography>Imagem Destaque Mobile</Typography>
							<ul>
								<li>Recomendado tamanho 686x540 para Imagem</li>
							</ul>
						</Grid>

						<Grid item xs={12}>
							<Box display='flex'>
								<Box className={style.btnUploadForm} display='flex' justifyContent="center" alignItems="center">
									<input accept="image/*" id="file_mobile" name="file_mobile" type="file" onChange={handleFile} />
									<label htmlFor="file_mobile">
										<IconCustom coloricon="#fff" aria-label="imagem destaque mobile" component="span">
											<PhotoCamera />
										</IconCustom>
									</label>
								</Box>

								<Box className={`${style.boxPreviewImg} fixed`}>
									{previewImg.file_mobile ? <img src={previewImg.file_mobile} alt="" /> : data.image_mobile && <img src={data.image_mobile} alt="" />}
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Typography>Imagens Carroussel</Typography>
							<Box display='flex' flexWrap='wrap'>
								<Box className={style.btnUploadForm} display='flex' justifyContent="center" alignItems="center">
									<input accept="image/*" id="file-carousel" type="file" multiple onChange={handleFiles} />
									<label htmlFor="file-carousel">
										<IconCustom coloricon="#fff" aria-label="imagem Carroussel" component="span">
											<PhotoCamera />
										</IconCustom>
									</label>
								</Box>

								{previewImgCarousel.map(img => (
									<Box key={img} className={`${style.boxPreviewImg} fixed`}>
										<img src={img} alt="" />
									</Box>
								))}

								{data.gallery.map(img => (
									<Box key={img} className={`${style.boxPreviewImg} fixed`} >
										<img src={img} alt="" />
									</Box>
								))}
							</Box>
						</Grid>

						<Grid item xs={12}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											onChange={makeBanner}
										/>
									}
									label="Adicionar um banner para essa postagem ?"
								/>
							</FormGroup>
						</Grid>

						{addBanner &&
							<>

								<Grid item xs={12} sm={6} md={4}>
									<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
										<KeyboardDatePicker
											inputVariant="outlined"
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											format="dd/MM/yyyy"
											margin="none"
											id="date_input"
											label="Data de entrada"
											name="date_input"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											helperText=""
											value={banner.date_input}
											onChange={e => handleDate('date_input', e)}
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
										<KeyboardDatePicker
											inputVariant="outlined"
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											helperText=""
											format="dd/MM/yyyy"
											margin="none"
											id="date_output"
											label="Data de saída"
											name="date_output"
											fullWidth
											InputLabelProps={{
												shrink: true,

											}}
											value={banner.date_output}
											onChange={e => handleDate('date_output', e)}
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="status-banner">Status</InputLabel>
										<Select
											labelId="status-banner"
											id="status-banner"
											name="status"
											value={banner.status}
											onChange={handleValuesBanner}
											label="Status"
										>
											<MenuItem value={"blocked"}>Bloqueado</MenuItem>
											<MenuItem value={"actived"}>Ativo</MenuItem>
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="position-banner">Posição do Banner</InputLabel>
										<Select
											labelId="position-banner"
											id="position-banner"
											name="position"
											value={banner.position}
											onChange={handleValuesBanner}
											label="Posição do Banner"
										>
											{positions.map(position => (
												<MenuItem key={position.id} value={position.id}>{position.position_name}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6} md={4} >
									<FormControl variant="outlined" fullWidth>
										<InputLabel htmlFor="order-banner" >Ordem</InputLabel>
										<OutlinedInput
											name="order"
											id="order-banner"
											value={banner.order}
											onChange={handleValuesBanner}
											label="ordem"
											type='tel'
											startAdornment={
												<InputAdornment position="start">
													<ViewModule />
												</InputAdornment>
											}
										/>
										<FormHelperText></FormHelperText>
									</FormControl>
								</Grid>
							</>
						}

						<Grid item xs={12} >
							<Box display="flex" justifyContent="space-between">
								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
												checked={data.status === 'publish' && true}
												value="publish"
												name="status"
												onChange={handleValues}
											/>
										}
										label="Publicar"
									/>
								</FormGroup>
								<CustomButtom
									onClick={envPost}
									endIcon={
										<Save />
									}
								>
									salvar
								</CustomButtom>
							</Box>
						</Grid>

					</Grid>
				</form>
			</Container>
		</Manager>
	)

}

export default FormPost