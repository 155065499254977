import React, { useState, useEffect } from 'react';

import Manager from '../../../Components/Manager';

import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, Typography } from '@material-ui/core';
import { ContentManager, CustomButtom, IconCustom, TitleList } from '../../../Components/Customs';
import { Add, ArrowBack, Delete, Edit } from '@material-ui/icons';
import stylePagesManager from '../style';
import api from '../../../Services/api';
import { toast } from 'react-toastify';

function Posts() {

	const style = stylePagesManager()

	const [rows, setRows] = useState([])
	const [idPost, setIdPost] = useState()
	const [modalDelete, setModalDelete] = useState(false)

	async function getPosts() {
		const res = await api.get('post/listAll')
		setRows(res.data.rows)
	}

	function handleModalDelete(id) {
		setIdPost(id)
		setModalDelete(true)
	}

	function handleClose() {
		setIdPost(0)
		setModalDelete(false)
	}

	async function deletePost() {
		try {
			await api.delete(`post/${idPost}`)
			toast.success('Postagem deletada com sucesso')
			getPosts()
		} catch (error) {
			toast.error('Falha ao deletar postagem')
		}

		handleClose()
	}

	useEffect(() => {
		getPosts()
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="xl">
					<Box display='flex' justifyContent='center'>
						<ContentManager width="80%">
							<Box display="flex" justifyContent="space-between">
								<TitleList>Postagens</TitleList>
								<CustomButtom
									href='/admin/postagem'
									startIcon={
										<Add />
									}
								>
									Novo post
							</CustomButtom>
							</Box>

							<Box display='flex' justifyContent='center'>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<Hidden smDown>
												<th className={`title`}>ID</th>
											</Hidden>
											<th className={`title`}>Imagem</th>
											<th className={`title`}>Título da postagem</th>
											<th className={`title`}>status</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{rows.map((row) => (
											<tr key={row.id}>
												<Hidden smDown>
													<td>{row.id}</td>
												</Hidden>
												<td><Box className={style.imageBannerList}><img src={row.image_mobile} alt="" /></Box></td>
												<td>{row.title}</td>
												<td>{row.status === 'publish' ? 'publicado' : 'Não publicado'} </td>
												<td className='settings'>
													<IconCustom coloricon="orange" href={`/admin/postagem/${row.id}`}>
														<Edit />
													</IconCustom>

													<IconCustom coloricon="red" onClick={e => handleModalDelete(row.id)}>
														<Delete />
													</IconCustom>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
						</ContentManager>
					</Box>
				</Container>
			</Manager>
			<Dialog open={modalDelete} onClose={handleClose} aria-labelledby='form-post' className={style.modalForm}>
				<DialogTitle id='form-post'>Deletar Postagem</DialogTitle>
				<DialogContent>
					<Typography className='content_modal_delete'>Deseja realmente deletar a postagem ? </Typography>
				</DialogContent>
				<DialogActions>
					<CustomButtom
						bg="orange"
						startIcon={<ArrowBack />}
						onClick={handleClose}
					>
						voltar
				</CustomButtom>

					<CustomButtom
						bg="red"
						endIcon={<Delete />}
						onClick={deletePost}
					>
						deletar
				</CustomButtom>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Posts;