import React, { useEffect } from 'react';

import { TitlePage } from '../../Components/Customs';
import Header from '../../Components/Header';

import { Box, Container, Hidden, Typography } from '@material-ui/core';
import { Brightness1Rounded, RadioButtonCheckedOutlined, RadioButtonUncheckedRounded } from '@material-ui/icons';
import stylePageHistory from './style';

import informatizacao from '../../assets/img/site.jpg'
import galpao_estoque from '../../assets/img/galpao-estoque.jpg'
import galpao_site from '../../assets/img/site.jpg'
import balcao_caixas from '../../assets/img/site.jpg'
import nossa_cria from '../../assets/img/nossa-cria.jpg'
import site from '../../assets/img/site.jpg'
import feiras from '../../assets/img/feiras.jpg'
import Footer from '../../Components/Footer';

import ReactGa from 'react-ga'


function History() {


	useEffect(() => {
		ReactGa.pageview('/história')
	}, [])
	const style = stylePageHistory()

	return (
		<>
			<Header />

			<Container maxWidth={false}>

				<Container maxWidth="md" style={{ padding: 0 }} >
					<Box className={style.textHistory}>

						<TitlePage>Nossa História</TitlePage>

						<Typography>
							Em 1990, sempre no mesmo endereço do Tatuapé. Meire fundou em um pequeno
							quarto da sua casa o início da história da Loja Santo Antonio. A princípio
							era uma pequena loja de varejo que tinha como intuito, incentivar as donas
							de casa a iniciar seu próprio negócio através da culinária e artesanato.
						</Typography>

						<Typography>
							Devido a sua dedicação, o pequeno varejo veio ganhando seu espaço no mercado,
							e os produtos para fabricação caseira passaram a ser evidência na vida das pessoas
							que procuram ter como fonte de renda o trabalho executado em sua própria cozinha.
						</Typography>

						<Typography>
							Com quase 30 anos de confiança, a loja permanece com a mesma missão de
							auxiliar os seus clientes através de conhecimento, disponibilizando cursos com
							profissionais reconhecidos no mercado, visando sempre evoluir e inovando
							da melhor forma.
						</Typography>

					</Box>
				</Container>

				<Box>
					<ul className={style.timeline}>
						<li className='contentRight'>
							<Box display="flex" flexDirection="column" className={style.timelineContentTop}>
								<span>1990</span>
								<span>1 funcionário</span>
							</Box>
							<Box display="flex" className={style.timelineSeparate}>
								<RadioButtonCheckedOutlined className={style.timelineDot} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}></Box>
						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>1996</span>
								<span>Informatização da loja</span>
								<span></span>
								{/* <Box className={style.imgTimeline}><img src={informatizacao} alt=""/></Box> */}
							</Box>
						</li>

						<li className='contentRight'>
							<Box display="flex" flexDirection="column" className={style.timelineContentTop}>
								<span>2000</span>
								<span>10 funcionários</span>
							</Box>
							<Box display="flex" className={style.timelineSeparate}>
								<RadioButtonUncheckedRounded className={style.timelineDot} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}></Box>
						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2001</span>
								<span>Mudança de balcão para caixas</span>
								<span></span>
								{/* <Box className={style.imgTimeline}><img src={balcao_caixas} alt=""/></Box> */}
							</Box>

						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2005</span>
								<span>Criação da nossa cria</span>
								<span></span>
								<Box className={style.imgTimeline}><img src={nossa_cria} alt="" /></Box>
							</Box>

						</li>

						<li className='contentRight'>
							<Box display="flex" flexDirection="column" className={style.timelineContentTop}>
								<span>2010</span>
								<span>25 funcionários</span>
							</Box>
							<Box display="flex" className={style.timelineSeparate}>
								<RadioButtonUncheckedRounded className={style.timelineDot} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}></Box>
						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2012</span>
								<span>Criação do site</span>
								<span></span>
								{/* <Box className={style.imgTimeline}><img src={site} alt=""/></Box> */}
							</Box>

						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2015</span>
								<span>Participação nas feiras</span>
								<span></span>
								<Box className={style.imgTimeline}><img src={feiras} alt="" /></Box>
							</Box>

						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2016</span>
								<span>Galpão novo do site</span>
								<span></span>
								<Box className={style.imgTimeline}><img src={galpao_site} alt="" /></Box>
							</Box>

						</li>

						<li className='contentLeft'>
							<Box className={style.timelineContentTop}></Box>
							<Box display="flex" className={style.timelineSeparate}>
								<Brightness1Rounded className={style.timelineDot} style={{ fontSize: 10 }} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}>
								<span>2019</span>
								<span>Galpão novo do estoque</span>
								<span></span>
								<Box className={style.imgTimeline}><img src={galpao_estoque} alt="" /></Box>
							</Box>

						</li>

						<li className='contentRight'>
							<Box display="flex" flexDirection="column" className={style.timelineContentTop}>
								<span>2020</span>
								<span>134 funcionários</span>
							</Box>
							<Box display="flex" className={style.timelineSeparate}>
								<RadioButtonUncheckedRounded className={style.timelineDot} />
								<span className={style.connector}></span>
							</Box>
							<Box display="flex" flexDirection="column" className={style.timelineContentBottom}></Box>
						</li>
					</ul>
				</Box>

			</Container>

			<Footer fixed={true} />

		</>
	)
}

export default History;