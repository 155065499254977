import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core';
import { TitlePage } from '../../Components/Customs'

const useStyle = makeStyles( theme => ({
	root: {
		width: '100vw',
		height: '100vh'
	}
}))

function NotFound() {
	const style = useStyle()
	return (
		<>
			<Box className={style.root} display='flex' justifyContent='center' alignItems='center'>
				<Box>
					<TitlePage>Serviço indisponível no momento!</TitlePage>
					<Typography>

						<li>Disponível apenas em horário de trabalho</li>
						<li>Acesso disponível apenas no local de trabalho</li>

					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default NotFound;