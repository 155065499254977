import { makeStyles } from '@material-ui/core'

const stylePageCodeConduct = makeStyles((theme) => ({
	root: {
		paddingBottom: 50,
	},

	content: {
		'&, *': {
			fontSize: 18,
		},

		'& ul': {
			[theme.breakpoints.down('xs')]: {
				paddingLeft: 20,
			}
		},

		'& .MuiTypography-root': {
			margin: '15px 0',

			[theme.breakpoints.down('xs')]: {
				fontSize: 14,
			},

			'& b': {
				fontSize: 20,

				[theme.breakpoints.down('xs')]: {
					fontSize: 16,
				}

			}
		}
	},

	boxObs: {
		border: '2px solid #f4393c',
		borderRadius: 25,
		padding: '15px 25px',
	}
}));

export default stylePageCodeConduct