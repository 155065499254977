import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import api from '../../Services/api';

import Header from '../../Components/Header'

import stylePageBirthdays from './style';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { TitlePage } from '../../Components/Customs';
import balao from '../../assets/img/balao.png'
import Footer from '../../Components/Footer';

import ReactGa from 'react-ga'


function Birthdays() {

	const style = stylePageBirthdays()

	const [birthdays, setBirthdays] = useState([])

	async function getBirthdays() {
		const res = await api.get('public/date_birth')
		setBirthdays(res.data)
	}

	function verifyDateBirthday(date) {
		const birthday = DateTime.fromISO(date).setLocale('pt').toFormat('dd/MM')
		const today = DateTime.local().setLocale('pt').toFormat('dd/MM')

		if (birthday === today) {
			return 'birthday-now'
		}
	}

	useEffect(() => {
		getBirthdays()

		ReactGa.pageview('/aniversáriantes')
	}, [])

	return (
		<>
			<Header />

			<Container maxWidth="lg" className={style.root}>

				<TitlePage align='center'>Aniversáriantes</TitlePage>
				<Grid container spacing={2}>
					{birthdays.map(birth => (
						<Grid key={birth.id} item xs={6} sm={4} md={3} lg={2}>

							<Box className={`${style.boxBirthday} ${verifyDateBirthday(birth.date_birth)}`}>

								<Box className='thumb'>
									<img src={birth.path_image} alt="" />
								</Box>

								<Box display='flex' flexDirection='column' className='infos-birthday' >
									<Typography align='center' className='name'>{birth.name}</Typography>
									<Typography align='center' className='date'>{DateTime.fromSQL(birth.date_birth).toFormat('dd/MM')}</Typography>
								</Box>

								{DateTime.fromISO(birth.date_birth).toFormat('dd/MM') === DateTime.local().toFormat('dd/MM') &&
									<Typography className='balao'>
										<img src={balao} alt="" />
									</Typography>
								}

							</Box>
						</Grid>
					))}
				</Grid>
			</Container>

			<Footer />
		</>
	)
}

export default Birthdays;