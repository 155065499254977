import { makeStyles } from '@material-ui/core'

const stylePageBirthdays = makeStyles((theme) => ({
	root: {
		paddingBottom: 50,
    paddingTop: 25,
	},

	boxBirthday: {
		position: 'relative',
		padding: 15,
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		borderRadius: 10,
		overflow: 'hidden',

		'&.birthday-now': {
			// background: '#f4393c',
		},

		'& .balao': {
			position: 'absolute',
			top: 10,
			width: 50,
			right: 10,

			'& img': {
				maxWidth: '100%'
			}
		},

		'& .thumb': {
			width: '100%',
			margin: '0 auto',

			[theme.breakpoints.up('xs')]: {
				width: '90%',
				height: '35vw',
				textAlign: 'center',
			},

			[theme.breakpoints.up('sm')]: {
				width: '90%',
				height: '20vw',
				textAlign: 'center',
			},

			[theme.breakpoints.up('md')]: {
				width: 200,
				height: 200,
			},

			[theme.breakpoints.up('lg')]: {
				width: 175,
				height: 175,
			},

			'& img': {
				maxWidth: '100%',
				height: '100%',
			}
		},

		'& .infos-birthday': {
			paddingTop: 15,

			'& .name': {
				color: '#271e1e',

				[theme.breakpoints.down('md')]: {
					fontSize: '2vw'
				},

				[theme.breakpoints.down('sm')]: {
					fontSize: '2.5vw'
				},

				[theme.breakpoints.down('xs')]: {
					fontSize: '3.5vw'
				},

			},

			'& .date': {
				color: '#f4393c',
				fontWeight: 'bold',


				[theme.breakpoints.down('md')]: {
					fontSize: '2.5vw'
				},

				[theme.breakpoints.down('sm')]: {
					fontSize: '3.5vw'
				},

				[theme.breakpoints.down('xs')]: {
					fontSize: '5vw'
				},

			}
		}
	}
}))

export default stylePageBirthdays