import { makeStyles } from "@material-ui/core"

const stylePageHistory = makeStyles((theme) => ({
	root: {

	},

	textHistory: {
		'& .MuiTypography-root': {
			paddingTop: 15,
			paddingBottom: 15,
		}
	},

	timeline: {
		listStyle: 'none',
		display: 'flex',
		padding: 0,
		marginBottom: 30,
		marginTop: 30,
		justifyContent: 'center',

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},

		'& li': {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'row',
			},

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					'& $imgTimeline': {
						display: 'flex !important',
						top: 160,
					}
				},
			},

			'&.contentLeft': {
				[theme.breakpoints.down('sm')]: {
					'& > div': {
						'&:nth-child(1)': {
							display: 'none',
						},
					}
				},
			},

			'&.contentRight': {
				[theme.breakpoints.down('sm')]: {
					'& > div': {
						'&:nth-child(1)': {
							order: 2,
							alignItems: 'flex-start'
						},

						'&:nth-child(2)': {
							order: 1
						},
					}
				},
			}

		},

		[theme.breakpoints.up('md')]: {
			'& li:nth-child(2)': {
				'& > div:nth-child(3)': {
					width: 100,
				}
			},

			'& li:nth-child(4)': {
				'& > div:nth-child(3)': {
					width: 135,
				}
			},

			'& li:nth-child(5)': {
				'& > div:nth-child(3)': {
					width: 80,
				}
			},

			'& li:nth-child(7)': {
				'& > div:nth-child(3)': {
					width: 60,
				}
			},

			'& li:nth-child(8)': {
				'& > div:nth-child(3)': {
					width: 100,
				}
			},

			'& li:nth-child(9)': {
				'& > div:nth-child(3)': {
					width: 100,
				}
			},

			'& li:nth-child(10)': {
				'& > div:nth-child(3)': {
					width: 100,
				}
			},
		}
	},

	timelineSeparate: {
		alignItems: 'center',

		'& svg': {
			color: '#b01010',
		},

		[theme.breakpoints.up('md')]: {
			height: 30,
		},

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			width: 30,
			minHeight: 80,
		}
	},

	connector: {
		display: 'block',

		[theme.breakpoints.up('md')]: {
			height: 2,
			width: '100%',
		},

		[theme.breakpoints.down('sm')]: {
			width: 2,
			height: '100%',
		},

		background: '#b01010'
	},

	timelineContentTop: {

		[theme.breakpoints.up('md')]: {
			width: 50,
			minHeight: 50,
		},

		[theme.breakpoints.down('sm')]: {
			minWidth: 110,
		},

		'& span': {

			[theme.breakpoints.up('md')]: {
				whiteSpace: 'nowrap',
			},

			[theme.breakpoints.down('sm')]: {
				textAlign: 'right',
			},

			'&:first-child': {
				fontWeight: 'bold',
				fontSize: 18,
			},

			'&:last-child': {
				fontSize: 14,
			}
		}

	},

	timelineContentBottom: {

		'& span': {
			display: 'block',

			[theme.breakpoints.down('sm')]: {
				minWidth: 0,
			},

			'&:first-child': {
				fontWeight: 'bold',
				fontSize: 14,
			},

			'&:last-child': {
				fontSize: 12,
			}
		}

	},

	imgTimeline: {
		position: "absolute",
		width: 200,
		height: 200,
		display: 'none',
		zIndex: 99,

		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			display: 'block',
			paddingTop: 5,
			marginBottom: 30,
		},

	}

}))

export default stylePageHistory