import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from './authRoute'


import Login from '../Pages/Login'
import History from '../Pages/History'
import Mission from '../Pages/Mission'
import CodeConduct from '../Pages/CodeConduct'
import Notices from '../Pages/Notices'
import Birthdays from '../Pages/Birthdays'
import Home from '../Pages/Home'
import HomeManager from '../Pages/Manager/Home'
import Role from '../Pages/Manager/Role'
import Sectors from '../Pages/Manager/Sector'
import Departaments from '../Pages/Manager/Departaments'
import FormBanner from '../Pages/Manager/Banners/Form'
import Banners from '../Pages/Manager/Banners'
import FormPost from '../Pages/Manager/Post/Form'
import Posts from '../Pages/Manager/Post'
import SingleNotice from '../Pages/Notices/SingleNotice'
import Employee from '../Pages/Manager/Employee'
import FormEmployee from '../Pages/Manager/Employee/form'
import TimeLine from '../Pages/Manager/Employee/Timeline'
import Ratings from '../Pages/Manager/Employee/Ratings'
import Painel from '../Pages/Painel'
import Manual from '../Pages/Manual'
import NotFound from '../Pages/NotFound'
import Votation from '../Pages/Votation'

const Routes = () => {
	return(
		<Switch>
			<Route exact path={'/'} component={Login} />
			<Route path={'/indisponivel'} component={NotFound} />
			<Route exact path={'/historia'} component={History} isPrivate user="public" />
			<Route exact path={'/manual-de-integracao'} component={Manual} isPrivate user="public" />
			<Route exact path={'/missao-e-valores'} component={Mission} isPrivate user="public" />
			<Route exact path={'/codigo-de-conduta'} component={CodeConduct} isPrivate user="public"/>
			<Route exact path={'/destaques'} component={Home} isPrivate user="public"/>
			<Route exact path={'/noticias'} component={Notices} isPrivate user="public"/>
			<Route exact path={'/noticia/:name'} component={SingleNotice} isPrivate user="public" />
			<Route exact path={'/aniversariantes'} component={Birthdays} isPrivate user="public" />
			<Route exact path={'/painel'} component={Painel} />
			<Route exact path={'/votacao'} component={Votation} />

			<Route exact path={'/admin'} component={HomeManager} isPrivate user="admin"/>
			<Route exact path={'/admin/cargos'} component={Role} isPrivate user="admin"/>
			<Route exact path={'/admin/setores'} component={Sectors} isPrivate user="admin"/>
			<Route exact path={'/admin/departamentos'} component={Departaments} isPrivate user="admin"/>

			<Route exact path={'/admin/banners/novo'} component={FormBanner} isPrivate user="admin"/>
			<Route exact path={'/admin/banners/editar/:id'} component={FormBanner} isPrivate user="admin"/>
			<Route exact path={'/admin/banners'} component={Banners} isPrivate user="admin"/>

			<Route exact path={'/admin/postagem'} component={FormPost} isPrivate user="admin"/>
			<Route exact path={'/admin/postagem/:id'} component={FormPost} isPrivate user="admin"/>
			<Route exact path={'/admin/postagens'} component={Posts} isPrivate user="admin" />

			<Route exact path={'/admin/funcionarios'} component={Employee} isPrivate user="admin"/>
			<Route exact path={'/admin/funcionarios/avaliacoes'} component={Ratings} isPrivate user="admin"/>
			<Route exact path={'/admin/funcionario/novo'} component={FormEmployee} isPrivate user="admin"/>
			<Route exact path={'/admin/funcionario/editar/:id'} component={FormEmployee} isPrivate user="admin"/>

			<Route exact path={'/admin/timeline'} component={TimeLine} isPrivate user="admin" />

			<Redirect to={'/'} />
		</Switch>
	)
}

export default Routes