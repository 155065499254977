import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import api from '../../Services/api';
import QRCode from 'qrcode.react'
import stylePainelTv from './style';
import { DateTime } from 'luxon';

import foto from '../../assets/img/foto_vazia.png'
import balao from '../../assets/img/balao.png'


function formateDate(date) {
	const d = DateTime.fromISO(date).setLocale('pt')
	return `${d.toFormat('dd')} de ${d.toFormat('MMMM')}`
}



function createSlide(banners, config) {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		pauseOnHover: false,
		...config,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Slider {...settings}>
			{banners}
		</Slider>
	)
}

function bannerHighlights(imgs) {

	const banners = imgs.map((b) => (
		<>
			<Box className='box-banner highlights' display='flex' justifyContent='center' alignItems='center'>
				<img src={b.image_url} alt={b.title} />
				<Box className='infos-banner' display='flex' justifyContent='space-between'>
					<Box className='title-date' display='flex' flexDirection='column' justifyContent='center' >
						<Typography className='title'>{b.title}</Typography>
						<Typography className='date'>{formateDate(b.date_input)}</Typography>
					</Box>
					<Box className='qr-code'>
						<Typography align='center' className='read-more'>leia mais</Typography>
						<QRCode value={b.link} />
					</Box>
				</Box>
			</Box>
		</>
	))

	return createSlide(banners, { autoplaySpeed: 4000 })
}

function bannerNews(news) {
	const posts = news.map((p) => (
		<>
			{p !== undefined &&
				<Box className='box-banner news' display='flex'>
					<Box className='image-new' display='flex' justifyContent='center' alignItems='center'>
						<img src={p.image_url} alt={p.title} />
					</Box>
					<Box className='infos-banner' display='flex' justifyContent='space-between'>
						<Box className='title-date' display='flex' justifyContent='space-between' flexDirection='column'>
							<Typography className='title'>{p.title}</Typography>
							<Typography className='date'>{formateDate(p.date_input)}</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Box className='qr-code'>
								<Typography className='read-more' align='center'>leia mais</Typography>
								<QRCode value={p.link} />
							</Box>
						</Box>

					</Box>
				</Box>
			}
		</>
	))

	return createSlide(posts, { autoplaySpeed: 5000 })
}

function Painel() {

	const style = stylePainelTv()

	const [banners, setBanners] = useState({
		loading: false,
		highlights: [],
		news: [],
	})

	const [dateNow, setDateNow] = useState('')
	const [timeReal, setTimeReal] = useState('')
	const [birthdays, setBirthdays] = useState([])


	setInterval(() => {
		getDateNow()
	}, 1000)

	async function getBanners() {
		const arr = []
		const highlights = await api.get('public/bannerprod/destaques')
		const aviso1 = await api.get('public/bannerprod/aviso-1')
		const aviso2 = await api.get('public/bannerprod/aviso-2')
		const aviso3 = await api.get('public/bannerprod/aviso-3')
		// const news = await api.get('public/post?limit=6')

		arr.push(aviso1.data[0])
		arr.push(aviso2.data[0])
		arr.push(aviso3.data[0])

		setBanners({
			loading: true,
			highlights: highlights.data,
			news: arr
		})
	}

	const handleSectorEmployy = (sector) => {
		const sectors = {
			"1": 'Escritório',
			"2": 'Loja',
			"5": 'Site',
			"7": 'Galpão',
			"6": 'Nossa Cria',
		}
		return sectors[sector]
	}

	async function getBirthdays() {
		const res = await api.get('public/date_birth')
		setBirthdays(res.data)
	}

	function getDateNow() {
		const d = DateTime.local().setLocale('pt')
		const day = d.toFormat('dd')
		const month = d.toFormat('MMMM')
		const dayWeek = d.toFormat('cccc')
		const time = d.toFormat('TT')

		setDateNow(`${dayWeek}, ${day} de ${month}`)
		setTimeReal(time)
	}

	useEffect(() => {
		getBanners()
		getDateNow()
		getBirthdays()
	}, [])

	return (
		<>
			<Container maxWidth='xl' className={style.root}>
				<Grid container>
					<Grid container item md={9} className={style.boxLeft}>

						<Grid item xs={12} className={style.bannersHighlights} >
							{banners.loading &&
								bannerHighlights(banners.highlights)
							}
						</Grid>

						<Grid item xs={12} className={style.bannersNews}>
							{banners.loading &&
								bannerNews(banners.news)
							}
						</Grid>

					</Grid>

					<Grid container item md={3} className={style.boxRight}>
						<Grid item xs={12}>
							<Box className={style.date_now}>
								<Typography align='center' className='date-now'>{dateNow}</Typography>
								<Typography align='center' className='time-real'>{timeReal}</Typography>
							</Box>

							<Box className={style.boxBirthday}>
								<Typography className='title-box' align='center'>Aniversáriantes</Typography>

								{birthdays.map((birthday, i) => {
									if (i < 5) {
										return (
											<Box key={birthday.id} display="flex" className='birthday'>
												<div className='foto_birthday'>
													<img src={birthday.path_image} alt="" />
												</div>

												<Box display="flex" flexDirection="column" justifyContent="center" className='infos-birthday'>
													<Typography className='date'><b>{DateTime.fromISO(birthday.date_birth).toFormat('dd/MM')}</b> - {handleSectorEmployy(birthday.sector_id)}</Typography>
													<Typography className='name' >{birthday.name}</Typography>
												</Box>

												{DateTime.fromISO(birthday.date_birth).toFormat('dd/MM') === DateTime.local().toFormat('dd/MM') &&
													<Typography className='balao'>
														<img src={balao} alt="" />
													</Typography>
												}
											</Box>
										)
									}
								})}
							</Box>

						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}

export default Painel;