import React, { useEffect, useState } from 'react'
import LuxonUtils from '@date-io/luxon';
import { DateTime, Zone } from 'luxon'

import { Avatar, Box, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from '@material-ui/core'

import Manager from '../../../Components/Manager'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import api from '../../../Services/api';
import stylePagesManager from '../style';
import { CustomButtom, IconCustom, TitleList } from '../../../Components/Customs';
import { Add, Delete, Edit, PhotoCamera } from '@material-ui/icons';
import { CepMask, CpfMask, PhoneMask, RgMask } from '../../../Components/Masks';
import { toast } from 'react-toastify';
import ModalHistory from '../../../Components/ModalHistory';
import { date } from 'yup';


DateTime.local().setLocale('pt')

function FormEmployee({ match }) {

	const style = stylePagesManager()

	const [openModal, setOpenModal] = useState(false)
	const [idHistory, setIdHistory] = useState(0)
	const [roles, setRoles] = useState([])
	const [sectors, setSectors] = useState([])
	const [dptos, setDptos] = useState([])
	const [historys, setHistorys] = useState({
		limit: 10,
		pages: 0,
		page: 0,
		count: 0,
		rows: []
	})
	const [responsible, setResponsible] = useState([])
	const [previewImg, setPreviewImg] = useState(false)

	const [data, setData] = useState({
		name: "",
		lastname: "",
		gender: "M",
		date_birth: DateTime.local().toISO(),
		rg: "",
		cpf: "",
		pis: "",
		ctps: "",
		email: "",
		children: 0,
		name_mother: "",
		address: "",
		district: "",
		cep: "",
		cel: "",
		tel: "",
		city: "",
		admission_date: DateTime.local().toISO(),
		sector_id: 0,
		role_id: 0,
		departament_id: 0,
		respond_to: 0,
		type_user: "user",
		file: [],
		is_active: 1,
		demission_date: DateTime.local().toISO(),
		demission_reason: ''
	})

	async function getEmployee() {
		const res = await api.get(`employee/${match.params.id}`)
		console.log(res)
		setData({
			...data,
			name: res.data[0].name,
			lastname: res.data[0].lastname,
			gender: res.data[0].gender,
			date_birth: DateTime.fromSQL(res.data[0].date_birth).toISO(),
			rg: res.data[0].rg,
			cpf: res.data[0].cpf,
			pis: res.data[0].pis,
			email: res.data[0].email,
			ctps: res.data[0].ctps,
			children: res.data[0].children,
			name_mother: res.data[0].name_mother,
			address: res.data[0].address,
			district: res.data[0].district,
			cep: res.data[0].cep,
			cel: res.data[0].cel,
			tel: res.data[0].tel,
			city: res.data[0].city,
			admission_date: DateTime.fromSQL(res.data[0].admission_date).toISO(),
			sector_id: res.data[0].sector_id,
			role_id: res.data[0].role_id,
			departament_id: res.data[0].departament_id,
			respond_to: res.data[0].respond_to,
			type_user: res.data[0].type_user,
			is_active: res.data[0].is_active,
			demission_date: DateTime.fromSQL(res.data[0].demission_date).toISO(),
			demission_reason: res.data[0].demission_reason,
			path_image: res.data[0].path_image,
		})
	}

	function handleValues(e) {
		const { name, value } = e.target

		if (name === 'is_active') {
			if (value === 1) {
				return setData({ ...data, [name]: value, demission_date: DateTime.local().toISO(), demission_reason: '' })
			}
		}

		return setData({ ...data, [name]: value })

	}

	function handleFile(e) {
		const file = e.target.files[0]
		setData({ ...data, file: file })
		if (file) {
			setPreviewImg(URL.createObjectURL(file))
		}
	}

	function handleDate(name, e) {
		setData({ ...data, [name]: DateTime.fromISO(e).toISO() })
	}

	async function getDptos() {
		if (!match.params.id) {
			setData({ ...data, departament_id: 0 })
		}

		const res = await api.get(`departament/where?sector_id=${data.sector_id}`)
		setDptos(res.data)
	}

	async function getSectors() {
		const res = await api.get('sector/listAll')
		setSectors(res.data.rows)
	}

	async function getRoles() {
		const res = await api.get('role/listAll')
		setRoles(res.data.rows)
	}

	async function getResponds() {
		const res = await api.get('employee/listAll')
		setResponsible(res.data)
	}

	async function handleModalHistory(id = 0) {
		console.log(id)
		setIdHistory(id)
		setOpenModal(!openModal)
	}

	async function getHistorys() {
		const res = await api.get(`/employee_history/listpage/${match.params.id}`)
		setHistorys({
			...historys,
			limit: res.data.limit,
			pages: res.data.pages,
			count: res.data.count,
			rows: res.data.rows
		})
	}

	async function envEmployee(e) {
		e.preventDefault()
		const obj = new FormData()
		obj.append('name', data.name)
		obj.append('lastname', data.lastname)
		obj.append('gender', data.gender)
		obj.append('email', data.email)
		obj.append('date_birth', DateTime.fromISO(data.date_birth).toFormat('yyyy-MM-dd'))
		obj.append('rg', data.rg.replace(/[^0-9]/g, ''))
		obj.append('cpf', data.cpf.replace(/[^0-9]/g, ''))
		obj.append('pis', data.pis)
		obj.append('ctps', data.ctps)
		obj.append('children', data.children)
		obj.append('name_mother', data.name_mother)
		obj.append('address', data.address)
		obj.append('district', data.district)
		obj.append('cep', data.cep)
		obj.append('cel', data.cel.replace(/[^0-9]/g, ''))
		obj.append('tel', data.tel.replace(/[^0-9]/g, ''))
		obj.append('city', data.city)
		obj.append('admission_date', DateTime.fromISO(data.admission_date).toFormat('yyyy-MM-dd'))
		obj.append('sector_id', data.sector_id)
		obj.append('role_id', data.role_id)
		obj.append('departament_id', data.departament_id)
		obj.append('respond_to', data.respond_to)
		obj.append('type_user', data.type_user)
		obj.append('file', data.file)
		obj.append('is_active', data.is_active)

		if (data.is_active === 0) {
			obj.append('demission_date', DateTime.fromISO(data.demission_date).toFormat('yyyy-MM-dd'))
			obj.append('demission_reason', data.demission_reason)
		}

		if (match.params.id) {
			try {
				const res = await api.put(`employee/${match.params.id}`, obj)
				if (res.data.error) {
					toast.warning(res.data.error)
				} else {
					toast.success('Funcionário atualizado com sucesso!')
				}
			} catch (error) {
				toast.error('Falha ao atualizar funcionário!')
			}
		} else {
			try {
				const res = await api.post(`employee`, obj)
				if(res.data.error) {
					return toast.warning(res.data.error)
				}
				toast.success('Funcionário cadastrado com sucesso!')
			} catch (e) {
				toast.error('Falha ao cadastrar funcionário!')
			}
		}
	}

	useEffect(() => {
		if (data.sector_id) {
			getDptos()
		}
	}, [data.sector_id])

	useEffect(() => {
		getSectors()
		getRoles()
		getResponds()
		getHistorys()

		if (match.params.id) {
			getEmployee()
		}
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="md">
					<form onSubmit={envEmployee}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box display='flex' justifyContent='center'>
									<Avatar className={style.path_image} src={data.path_image} alt='' />
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth >
									<InputLabel htmlFor="name">Nome</InputLabel>
									<OutlinedInput
										id="name"
										name="name"
										value={data.name}
										onChange={handleValues}
										label="Nome"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="lastname">Sobrenome</InputLabel>
									<OutlinedInput
										id="lastname"
										name="lastname"
										value={data.lastname}
										onChange={handleValues}
										label="Sobrenome"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
									<KeyboardDatePicker
										inputVariant="outlined"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										format="dd/MM/yyyy"
										margin="none"
										id="date_birth"
										label="Data de Nascimento"
										name="date_birth"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										helperText=""
										value={data.date_birth}
										onChange={e => handleDate('date_birth', e)}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Sexo</FormLabel>
									<RadioGroup aria-label="gender" name="gender" value={data.gender} onChange={handleValues} style={{ flexDirection: 'row' }} >
										<FormControlLabel value="M" control={<Radio />} label="Masculino" />
										<FormControlLabel value="F" control={<Radio />} label="Feminino" />
									</RadioGroup>
								</FormControl>
							</Grid>

							<Grid item xs={3}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="children">Filhos</InputLabel>
									<OutlinedInput
										id="children"
										name="children"
										value={data.children}
										onChange={handleValues}
										label="Filhos"
										type='number'
									/>
								</FormControl>
							</Grid>

							<Grid item xs={9}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="name_mother">Nome da Mãe</InputLabel>
									<OutlinedInput
										id="name_mother"
										name="name_mother"
										value={data.name_mother}
										onChange={handleValues}
										label="Nome da Mãe"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="tel">Telefone Residencial</InputLabel>
									<OutlinedInput
										id="tel"
										name="tel"
										value={data.tel}
										onChange={handleValues}
										inputComponent={PhoneMask}
										label="Telefone Residencial"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="cel">Celular</InputLabel>
									<OutlinedInput
										id="cel"
										name="cel"
										value={data.cel}
										inputComponent={PhoneMask}
										onChange={handleValues}
										label="Celular"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="email">E-Mail</InputLabel>
									<OutlinedInput
										id="email"
										name="email"
										value={data.email}
										onChange={handleValues}
										label="E-mail"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="cep">CEP</InputLabel>
									<OutlinedInput
										id="cep"
										name="cep"
										value={data.cep}
										onChange={handleValues}
										inputComponent={CepMask}
										label="CEP"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="address">Endereço</InputLabel>
									<OutlinedInput
										id="address"
										name="address"
										value={data.address}
										onChange={handleValues}
										label="Endereço"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="district">Bairro</InputLabel>
									<OutlinedInput
										id="district"
										name="district"
										value={data.district}
										onChange={handleValues}
										label="Bairro"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="city">Cidade</InputLabel>
									<OutlinedInput
										id="city"
										name="city"
										value={data.city}
										onChange={handleValues}
										label="Cidade"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="rg">RG</InputLabel>
									<OutlinedInput
										id="rg"
										name="rg"
										value={data.rg}
										inputComponent={RgMask}
										onChange={handleValues}
										label="RG"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="cpf">CPF</InputLabel>
									<OutlinedInput
										id="cpf"
										name="cpf"
										value={data.cpf}
										inputComponent={CpfMask}
										onChange={handleValues}
										label="CPF"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="ctps">Carteira de trabalho</InputLabel>
									<OutlinedInput
										id="ctps"
										name="ctps"
										value={data.ctps}
										onChange={handleValues}
										label="Carteira de trabalho"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor="pis">Número do PIS</InputLabel>
									<OutlinedInput
										id="pis"
										name="pis"
										value={data.pis}
										onChange={handleValues}
										label="Número do PIS"
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="sector_id">Setores</InputLabel>
									<Select
										labelId="sector_id"
										id="sector_id"
										name="sector_id"
										value={data.sector_id}
										onChange={handleValues}
										label="Setores"
									>
										<MenuItem value={0}>Selecione um setor</MenuItem>
										{sectors.map((s) => (
											<MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="departament_id">Departamentos</InputLabel>
									<Select
										labelId="departament_id"
										id="departament_id"
										name="departament_id"
										value={data.departament_id}
										onChange={handleValues}
										label="Departamentos"
									>
										<MenuItem value={0}>Selecione um departamento</MenuItem>
										{dptos.map((d) => (
											<MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="role_id">Cargos</InputLabel>
									<Select
										labelId="role_id"
										id="role_id"
										name="role_id"
										value={data.role_id}
										onChange={handleValues}
										label="Cargos"
									>
										<MenuItem value={0}>Selecione um cargo</MenuItem>
										{roles.map((role) => (
											<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="respond_to">Responsável</InputLabel>
									<Select
										labelId="respond_to"
										id="respond_to"
										name="respond_to"
										value={data.respond_to}
										onChange={handleValues}
										label="Responsável"
									>
										<MenuItem value={0}>Selecione um responsável</MenuItem>
										{responsible.map((resp) => (
											<MenuItem key={resp.id} value={resp.id}>{resp.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
									<KeyboardDatePicker
										inputVariant="outlined"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										format="dd/MM/yyyy"
										margin="none"
										id="admission_date"
										label="Data de Admissão"
										name="admission_date"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										helperText=""
										value={data.admission_date}
										onChange={e => handleDate('admission_date', e)}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="type_user">Tipo de Usuário</InputLabel>
									<Select
										labelId="type_user"
										id="type_user"
										name="type_user"
										value={data.type_user}
										onChange={handleValues}
										label="Tipo de Usuário"
									>
										<MenuItem value={'user'}>Usuário</MenuItem>
										<MenuItem value={'admin'}>Administrador</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="is_active">Status do Funcionario</InputLabel>
									<Select
										labelId="is_active"
										id="is_active"
										name="is_active"
										value={data.is_active}
										onChange={handleValues}
										label="Status do Funcionario"
									>
										<MenuItem value={0}>Desligado</MenuItem>
										<MenuItem value={1}>Ativo</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							{data.is_active === 0 &&

								<>
									<Grid item xs={12} sm={6}>
										<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
											<KeyboardDatePicker
												inputVariant="outlined"
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												format="dd/MM/yyyy"
												margin="none"
												id="demission_date"
												label="Data de Demissão"
												name="demission_date"
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												helperText=""
												value={data.demission_date}
												onChange={e => handleDate('demission_date', e)}
											/>
										</MuiPickersUtilsProvider>
									</Grid>

									<Grid item xs={12}>
										<FormControl variant="outlined" fullWidth>
											<InputLabel htmlFor='demission_reason' shrink>Motivo da Demissão</InputLabel>
											<OutlinedInput
												multiline
												id='demission_reason'
												name='demission_reason'
												value={data.demission_reason}
												onChange={handleValues}
												rows={6}
												label='Motivo da Demissão'
												notched
											/>
										</FormControl>
									</Grid>
								</>

							}


							<Grid item xs={12}>
								<Typography>Observações sobre imagens:</Typography>
								<ul>
									<li>Recomendado tamanho 600x600 para Foto do funcionário</li>
								</ul>
							</Grid>

							<Grid item xs={12}>
								<Box display='flex'>
									<Box className={style.btnUploadForm} display='flex' justifyContent="center" alignItems="center">
										<input accept="image/*" id="file" type="file" onChange={handleFile} />
										<label htmlFor="file">
											<IconCustom coloricon="#fff" aria-label="Foto funcionário" component="span">
												<PhotoCamera />
											</IconCustom>
										</label>
									</Box>

									<Box className={`${style.boxPreviewImg} fixed`}>
										{previewImg ? <img src={previewImg} alt="" /> : data.path_image && <img src={data.path_image} alt="" />}
									</Box>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box display='flex' justifyContent={match.params.id ? 'space-between' : 'flex-end'}>
									{match.params.id &&
										<CustomButtom bg="orange" href='/admin/funcionarios'>
											voltar
										</CustomButtom>
									}

									<CustomButtom type='submit'>
										{match.params.id ? 'Atualizar' : 'cadastrar'}
									</CustomButtom>
								</Box>
							</Grid>
						</Grid>
					</form>

					{match.params.id &&
						<Box style={{ padding: '30px 0 50px 0' }}>
							<Box display='flex' justifyContent='space-between'>
								<TitleList>Histórico</TitleList>
								<CustomButtom bg='#58bf58' onClick={e => handleModalHistory()} startIcon={<Add />}> Adicionar histórico </CustomButtom>
							</Box>
							<Box>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<th className={`title`}>Tipo</th>
											<th className={`title`}>Observação</th>
											<th className={`title`}>Data</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{historys.rows.map((hist) => (
											<tr key={hist.id}>
												<td>{hist.type}</td>
												<td>{hist.observation}</td>
												<td>{DateTime.fromSQL(hist.date_occurrence).toFormat('dd/MM/yyyy')}</td>
												<td className='settings'>
													<IconCustom coloricon="orange" onClick={e => handleModalHistory(hist.id)}>
														<Edit />
													</IconCustom>

													{/* <IconCustom coloricon="red">
														<Delete />
													</IconCustom> */}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
						</Box>
					}

				</Container>
			</Manager>
			<ModalHistory openModal={openModal} id_employee={match.params.id} handleModalHistory={handleModalHistory} id={idHistory} />
		</>
	)
}

export default FormEmployee