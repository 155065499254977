import { makeStyles } from "@material-ui/core";

const styleBallotBox = makeStyles((theme) => ({
  form:{
    background: "#FAFAFA",
  },

  formControl: {
    margin: "2rem auto",
    width: "100%",
  },

  secondCategoryOut: {
    position: "absolute",
    left: "100vw",
    display: "none",
  },

  contestTitle: {
    textAlign: "center",
    color: "#F4393C",
    fontWeight: 900,
    fontSize: "20px",
  },

  p: {
    textAlign: "center",
    fontWeight: "400",
    color: "#212121",
    margin: "0 auto",
    marginBottom: "2rem",
  },

  divImg: {
    width: "40vw",
    heigth: "350px",
    border: "0.25rem solid #b01010",
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      width: "60vw",
    },
  },

  img: {
    maxHeigth: "350px !important",
  },

  radioGroup:{
    gap: "1.5rem",
  },

  radio: {
    justifyContent: "center",
  },

  divBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "1.5rem",
  },

  button: {
    color: "#FFFFFF",
    background: "#FF1744",
    borderRadius: "5px",
    border: "none",
    fontStyle: "normalize",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    width: "170px",
    heigth: "30px",
    padding: "0.5rem 0",

    "&:hover": {
      cursor: "pointer",
    },

    "&:disabled": {
      background: "grey",
      cursor: "not-allowed",
    },
  },
}));

export default styleBallotBox;
