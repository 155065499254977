const { makeStyles } = require("@material-ui/core");

const stylePainelTv = makeStyles( () => ({
	root: {
		padding: '0 !important',
		'&, *': {
			boxSizing: 'border-box',
		},

		'& $bannersNews, $bannersHighlights': {
			overflow: 'hidden',
			padding: 15,

			'& .box-banner': {
				background: '#fff',
				overflow: 'hidden',
				boxShadow: '-1px 1px 8px -5px #00000082',

				'& .qr-code': {
					background: '#f4393c',
					color: '#fff',
					padding: 15,
					borderRadius: 5,
					
					'& .read-more': {
						paddingBottom: 10,
						fontSize: 20,
						lineHeight: 1,
					}
				},

				'& img': {
					maxWidth: 'max-content',
				}
			},

			'& .slick-slider, .slick-list, .slick-track, .box-banner, .slick-slide > div': {
				height: '100%'
			}
		},

		'& .highlights': {
			position: 'relative',
			borderRadius: 5,

			'& img': {
				minHeight: 672,
			},

			'& .infos-banner': {
				zIndex: 999,
				position: "absolute",
				background: '#282a31bf',
				color: '#fff',
				width: '100%',
				bottom: 0,
				padding: '20px 35px',
				height: 200,

				'& .title-date' :{
					width: '80%'
				},

				'& .title': {
					maxHeight: 100,
					overflow: 'hidden',
					fontSize: 35,
					lineHeight: 1,
					marginBottom: 15
				},

				'& .date': {
					fontSize: 20,
				},

				'& canvas': {
					width: '100px !important',
					height: '100px !important',
				}
			}
		},

		'& .news': {

			borderRadius: 5,

			'& .qr-code': {
				height: 'max-content',
			},

			'& .image-new, .infos-banner': {
				overflow: 'hidden'
			},

			'& .image-new': {
				width: '35%',

				'& img': {
					height: '115%',
				}
			},

			'& .infos-banner': {
				width: '65%',
				padding: '20px 35px 20px 15px',

				'& canvas': {
					width: '100px !important',
					height: '100px !important',
				}
			},

			'& .title':{
				fontSize: 35,
				maxHeight: 110,
				lineHeight: 1,
				overflow: 'hidden',
			},

			'& .content':{
				fontSize: 20,
				maxHeight: 80,
				lineHeight: 1,
				paddingRight: 10,
				overflow: 'hidden',
			},

			'& .date':{
				fontSize: 20
			}
		}
	},

	bannersNews: {
		height: '35vh',
	},

	bannersHighlights: {
		height: '65vh',
		minHeight: 500,
	},

	boxRight: {
		padding: 15,
		height: '100vh',
		overflow: 'hidden',
	},

	date_now: {
		background: '#282a31',
		color: '#fff',
		padding: 15,
		borderRadius: 5,

		'& .date-now': {
			fontSize: '1.5vw'
		},

		'& .time-real': {
			fontSize: '3vw'
		}
	},

	boxBirthday: {
		marginTop: 15,
		padding: 20,
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		borderRadius: 5,
		height: '84%',

		'& .birthday': {
			paddingBottom: 7,
			position: 'relative',
		},

		'& .title-box': {
			color: '#f4393c',
			fontSize: 28,
			paddingBottom: 15

		},

		'& .balao': {
			position: 'absolute',
			right: 0,
			top: '2vw',
			width: '3vw',

		},

		'& .foto_birthday': {
			width: '33%',

			'& img': {
				maxWidth: '100%',
				height: 'auto'
			}
		},

		'& .infos-birthday': {
			paddingLeft: 15,

			'& .date': {
				color: '#f4393c',
				fontSize: '1.5vw',
			},

			'& .name': {
				color: '#271e1e',
				fontSize: '1.5vw',
			},
		}
	}


}))

export default stylePainelTv