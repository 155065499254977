import { createGlobalStyle } from 'styled-components'
import mainFonts from '../fonts'
import 'react-toastify/dist/ReactToastify.css'

const GlobalStyles = createGlobalStyle`
	${mainFonts}

	* {
		font-family: 'lato-regular', sans-serif !important;
	}

	img {
		max-width: 100%;
		height: auto,
	}

	.jodit-workplace, .jodit-wysiwyg {
		min-height: 500px !important;
	}

`;

export default GlobalStyles